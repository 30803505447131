import React from 'react';

import { Box } from '@core';
import { Header } from '@containers';
import { LOADING_OPTIONS } from '@components';

const HeaderCTA = ({
  title,
  description,
  buttons = [],
  image,
  mobileImage,
  shape = 'Blob8',
  maxHeight,
  padding,
  showRatingsAndPlatforms,
}) => (
  <Box {...padding}>
    <Header
      data={{
        text: ({ discountPercentage, t }) => ({
          title: <span dangerouslySetInnerHTML={{ __html: title }} />,
          description,
          buttons: buttons.map((button) => ({
            ...button,
            subtitle:
              button?.buttonProps?.discountSubtitle &&
              t('common:actions.nowDiscountOff', { discountPercentage }),
          })),
        }),
        image: () => ({
          desktop: {
            src: image,
            alt: title,
            position: {
              height: '100%',
              maxHeight,
              width: 'auto',
              top: 0,
              left: { lg: '86%', xl: '80%' },
            },
            loading: LOADING_OPTIONS.eager,
          },
          tablet: {
            src: mobileImage || image,
            alt: title,
            position: {
              maxHeight: '500px',
            },
            loading: LOADING_OPTIONS.eager,
          },
          mobile: {
            src: mobileImage || image,
            alt: title,
            position: {
              maxHeight: '500px',
            },
            loading: LOADING_OPTIONS.eager,
          },
        }),
      }}
      shape={shape}
      showRatingsAndPlatforms={showRatingsAndPlatforms}
    />
  </Box>
);

export default HeaderCTA;
